@import '../../assets/sass/main.scss';

$color-bg: #111;
$book-color-bg: lighten($color-bg, 20%);
$book-color-border: lighten(#000, 85%);
$book-border: 1px solid $book-color-border;

%book-rounded-corners {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.Root {
  width: 100%;
  h2 {
    text-align: center;
  }
  hr {
    margin: 1rem 0;
  }
}

.Books {
  font-size: 11px;
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  grid-gap: 2rem;
  @include respond(df, sm) {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }
}

.Book {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: rgba(173, 173, 173, 0.11);
  padding: 1rem;
  border: 2px solid transparent;
  border-radius: 5px;
  &:hover {
    // border-color: $primaryGreenish;
    // transition: all 1s;
    .Cover {
      box-shadow: 0 2px 4px 0 rgba(#000, 0.25), 0 9px 20px 0 rgba(#000, 0.45);

      img {
        transform: rotateY(-25deg);
        box-shadow: 1px 1px 5px 5px rgba(#000, 0.2);
      }
    }
  }
}

.ReadingBadge {
  position: absolute;
  top: 0;
  right: 0;
  color: rgb(0, 0, 0);
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;
  font-size: 11px;
  display: block;
  float: right;
  height: 32px;
  margin-right: -32px;
  transform: rotate(45deg);
  width: 96px;
  z-index: 2;

  &:after {
    content: '';
    border: 48px solid transparent;
    display: block;
    bottom: -9px;
    right: 0;
    position: absolute;
    border-bottom-color: #c8e232;
    z-index: -1;
  }
}

.Cover {
  @extend %book-rounded-corners;
  $height: 220px;
  $ratio: 0.6818;

  cursor: pointer;
  display: block;
  width: $height * $ratio;
  height: $height;
  position: relative;
  background: $book-color-bg;
  z-index: 1;
  box-shadow: 0 2px 4px 0 rgba(#000, 0.1), 0 9px 20px 0 rgba(#000, 0.25);
  overflow: hidden;
  transition: box-shadow 0.3s linear;
  margin: auto;
  @include respond(df, tab-p) {
    $height: 180px;
    height: $height;
    width: $height * $ratio;
  }
  @include respond(df, mbl) {
    $height: 100px;
    height: $height;
    width: $height * $ratio;
  }
  img {
    @extend %book-rounded-corners;

    width: inherit;
    height: inherit;
    transform-origin: 0 50%;
    transform: rotateY(0);
    transition: all 0.45s ease;
  }

  &::after,
  &::before {
    @extend %book-rounded-corners;

    content: '';
    display: block;
    width: inherit;
    height: inherit;
    position: absolute;
    z-index: -1;
    top: 0;
    background: $book-color-bg;
    border: $book-border;
  }

  &::before {
    left: -3px;
  }
  &::after {
    left: -6px;
  }
}

.Details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 1rem;
  .Title {
    text-align: center;
  }
  .Author {
    display: flex;
    .AuthorIcon {
      margin-right: 0.3rem;
    }
    & > * {
      font-size: 10px;
    }
  }
  .Rating {
    margin-top: 0.5rem;
  }
  .Genres {
    margin-top: 0.5rem;
  }
}
