.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 100vh;
}

.Container {
  position: relative;
  height: 100%;
}

.Content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding-bottom: 3rem;
}

.Particle {
  position: fixed;
}
