@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&display=swap');
@import './assets/sass/main.scss';

#InshaAllah-one-day-I-will-work-at-google {
  height: 100%;
}

h2 {
  @include respond(df, md) {
    font-size: 1.5rem;
  }
  @include respond(df, sm) {
    font-size: 1.2rem;
  }
}
p,
li {
  @include respond(df, sm) {
    font-size: 13px;
  }
}

* {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in Internet Explorer 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

button:focus {
  outline: none;
}

body {
  margin: 0 auto;
  font-family: 'Fira Sans', 'Roboto Slab', 'sans-serif';
  font-size: 1rem;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  transition: background-color 0.2s, color 0.2s;
  height: 100vh;
}

p {
  line-height: 1.7;
  // line-height: 4.8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

li {
  list-style: none;
  padding-bottom: 0.5em;
}
li ul li {
  list-style: none;
  padding-bottom: 0.1em;
}

// Scrollbar

/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff00001f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
